import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "home",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home/index"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/index"),
  },
  {
    name: "bgiLife",
    path: "/bgiLife",
    component: () => import("../views/bgiLife/index"),
  },
  {
    path: "/event",
    name: "event",
    component: () => import("../views/event/index"),
    // children: [
    //   {
    //     path: "/event/details",
    //     component: () => import("../views/event/details"),
    //   },
    // ],
  },
  {
    name: "adminssions",
    path: "/admissions",
    component: () => import("../views/adminssions/index"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news/index"),
  },
  {
    path: "/programs",
    name: "programs",
    component: () => import("../views/programs/index"),
  },
  
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
// new Vue({
//   el: '#app',
//   router,
//   mounted() {
//     console.log('当前路由信息：')
//     console.log(this.$route)
//   }
// })
// const originalPush =VueRouter.prototype.push
// VueRouter.prototype.push= function push(location){
//   return originalPush.call(this.location).catch(err=>err)
// }
//push
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

//replace
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // to表示去哪个路由，from表示来自哪个路由，next表示放行
  // 可加判断条件进行放行
  // console.log(from, "from");
  // console.log(next, "next");
  // console.log(router);
  // console.log(this.$route)
  console.log(localStorage.getItem('hIndex'));
  if (router.app.$store) router.app.$store.commit('changeHindex', localStorage.getItem('hIndex'))
  next();

});

export default router;
